.content {
  padding-top: 50px;
  max-width: 710px;
  margin: auto;
}

.konto {
  margin-left: 30px;
  font-weight: bold;
}

dd {
  margin-bottom: 10px;
}

.row .col {
  margin-right: 10px;
}

.media-table td {
  padding: 5px;
}

#navbar {
  background-color: #333;
  position: fixed;
  top: 0px;
  width: 697px;
  display: block;
  transition: top 0.3s;
}

#navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 15px;
  text-decoration: none;
  font-size: 17px;
}